import React, { useState } from 'react'
import './App.css';
import './fish.css';
import { fishAnimation } from './fish'

const API = 'https://y4iry6iceb.execute-api.eu-west-1.amazonaws.com/prod/phishing'

interface Result {
  url: string
  is_phishing?: boolean
  target?: string
  phishing_likelihood?: number
  message?: string
  screenshot_url?: string
  whitelisted?: boolean
  loading?: boolean
}

const brands = new Set([
  "Facebook",
  "PayPal",
  "Chase Bank",
  "Orange Bank",
  "Microsoft",
  "Google",
  "Amazon",
  "DHL",
])


function App() {
  const [result, setResult] = useState<Result | null>(null);

  function displayResult(result: Result | null) {
    if (!result) {
      return ""
    }
    if (result.loading) {
      return (
        <div className="result-loading">
          Loading result for "{result.url}"
          {fishAnimation()}
        </div>
      )
    }
    if (result.message) {
      return (
        <div className='result-error'>
          Error: {result.message}
          <br></br>while loading the result from <code>{result.url}</code>
        </div>
      )
    }
    return (
      <div className="result">
        <code>{result.url}</code>
        {result.is_phishing ?
          <div style={{ color: "#FF0000" }}>Phishing</div>
          : <div style={{ color: "#00FF00" }}>Safe</div>}
        <div>Phishing likelihood: {result.phishing_likelihood?.toFixed(2)}</div>
        {result.target ?
          <div>Target: {result.target}</div>
          : <div>No target detected</div>}
        {result.screenshot_url ?
          <img
            className='result-screenshot'
            alt="Phishy website screenshot"
            src={result.screenshot_url}
          />
          : ""}
      </div>
    )
  }

  function displayProtected(brands: Set<string>) {
    if (brands.size === 0) {
      return ""
    }
    return (
      <div className="protected-brands">
        Protected Brands:
        {[...brands].map((url) =>
          <p
            className='brand'
            key={url}
          >{url}
          </p>
        )}
      </div>
    )
  }

  function fetchResult(url: string) {
    fetch(API, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ url: url }),
    })
      .then(response => response.json())
      .then(result => {
        setResult(result)
      })
      .catch(err => {
        setResult({ url, message: "Server Error", loading: false })
      });

    setResult({ url: url, loading: true })
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault()
    fetchResult(e.target['url'].value)
  }


  return (
    <div className="App">
      <img src="/logo-no-bg.png" height="200rem" alt='logo' />
      <h2>
        <code>PhishVision-API</code>
      </h2>
      <form onSubmit={handleSubmit}>
        <input className="url-input" type="text" name="url"
          autoFocus={true} placeholder='Input a suspicious URL' />
      </form>
      <div className='result-container'>
        {displayResult(result)}
      </div>
      {displayProtected(brands)}
    </div>
  );
}

export default App;
